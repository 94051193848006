import { getExploreName } from '../utils/platform';

const urlParams = new URLSearchParams(window.location.search);
var topicName = urlParams.get('topic');
var sessionId = urlParams.get('id');
var host = urlParams.get('host');
var leaveid = urlParams.get('leaveid');
var name = urlParams.get('name');

if (sessionId != null) {
  localStorage.setItem("sessionId", sessionId);
}
if (leaveid != null) {
  localStorage.setItem("leaveid", leaveid);
}
if (topicName != null) {
  localStorage.setItem("topicName", topicName);
}
if (host != null) {
  localStorage.setItem("host", host);
}
export const devConfig = {
  sdkKey: 'ASCa0--LS9CXKib3V_scYw',
  sdkSecret: 'fCHWABkYLf9P6v5KxtZDCDsQKgORaTVX3R2a',
  webEndpoint: 'zoom.us',
  topic: topicName + "_" + sessionId,
  name: name,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  cloud_recording_option: 1,
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  role: host
};

