import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyC8I-mpMCmvtg5KaWDuZfvfw07wx2ihDGg",
  authDomain: "colony-new.firebaseapp.com",
  projectId: "colony-new",
  storageBucket: "colony-new.appspot.com",
  messagingSenderId: "989870192903",
  appId: "1:989870192903:web:ea1af134295638d2dbada2",
  measurementId: "G-J5W5PH1G23"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);